const PopUp = ({ text }) => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
        <defs></defs>
        <path
          id="question_1"
          className="cls-10"
          fill="#7c746c"
          d="M960,460.991a11,11,0,1,0,11,11,11,11,0,0,0-11-11h0Zm0.785,17.119a1.3,1.3,0,0,1-.888.342,1.345,1.345,0,0,1-.908-0.338,1.182,1.182,0,0,1-.389-0.942,1.216,1.216,0,0,1,.376-0.905,1.27,1.27,0,0,1,.921-0.367,1.252,1.252,0,0,1,1.272,1.272,1.188,1.188,0,0,1-.384.938h0Zm3.187-7.9a3.955,3.955,0,0,1-.7.943q-0.4.4-1.454,1.334a6.317,6.317,0,0,0-.465.465,1.959,1.959,0,0,0-.259.366,1.747,1.747,0,0,0-.133.334c-0.031.109-.078,0.305-0.14,0.585a0.95,0.95,0,0,1-1.015.885,1.031,1.031,0,0,1-.746-0.289,1.137,1.137,0,0,1-.3-0.861,3.19,3.19,0,0,1,.222-1.242,3.137,3.137,0,0,1,.589-0.92,13.251,13.251,0,0,1,.989-0.945c0.364-.317.627-0.558,0.789-0.72a2.4,2.4,0,0,0,.41-0.542,1.291,1.291,0,0,0,.166-0.647,1.516,1.516,0,0,0-.507-1.153,1.862,1.862,0,0,0-1.311-.469,1.812,1.812,0,0,0-1.382.474,3.779,3.779,0,0,0-.75,1.395,1.132,1.132,0,0,1-1.1.964,1.08,1.08,0,0,1-.807-0.338,1.029,1.029,0,0,1-.328-0.727,3.079,3.079,0,0,1,.52-1.643,4.008,4.008,0,0,1,1.519-1.378,4.776,4.776,0,0,1,2.328-.547,4.975,4.975,0,0,1,2.184.458,3.543,3.543,0,0,1,1.464,1.241,3.05,3.05,0,0,1,.516,1.707,2.64,2.64,0,0,1-.293,1.27h0Zm0,0"
          transform="translate(-949 -461)"
        />
      </svg>
      <div className="table-popup-desc">{text}</div>
    </>
  );
};

const DogTable = ({
  isSearch,
  caption,
  id,
  onTableClickButton,
  row,
  isOpen,
  isPopUp,
  popUpText,
}) => {
  const row_render = row.map((r, i) => (
    <tr key={i}>
      <td>{r.name}</td>
      <td>{r.price}</td>
    </tr>
  ));

  return (
    <table className="services-table">
      <caption
        style={{
          backgroundColor: isSearch ? "#6b8a92" : "#f8e8d8",
          color: isSearch ? "white" : "black",
        }}
      >
        <p>{caption}</p>
        {isSearch ? null : (
          <button className={isOpen ? "" : "down"} onClick={(e) => onTableClickButton(id)}>
            {isOpen ? "Свернуть" : "Развернуть"}
          </button>
        )}
      </caption>
      <tbody style={{ display: isOpen ? "" : "none" }}>
        <tr>
          <th>Порода/Услуга</th>
          <th className="table-popup">
            Стоимость
            {isPopUp === "1" ? <PopUp text={popUpText} /> : null}
          </th>
        </tr>
        {row_render}
      </tbody>
    </table>
  );
};

export default DogTable;
