const Search = ({ handlerOnChangeInput, value }) => (
  <div className="services-input-wrapper" id="services-input">
    <input
      onChange={handlerOnChangeInput}
      value={value}
      type="text"
      className="services-input"
      placeholder="Поиск породы"
    />
    <div className="services-input-icon"></div>
  </div>
);
export default Search;
