import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// const ul_nav = document.getElementById("nav-ul");

// const button_nav = document.getElementById("nav-icon");
// let menu_open = false;
// button_nav.addEventListener("click", () => {
//   if (!menu_open) {
//     ul_nav.style.display = "flex";
//     button_nav.classList.replace("nav-icon-menu_close", "nav-icon-menu_open");
//     menu_open = true;
//   } else {
//     ul_nav.style.display = "none";
//     button_nav.classList.replace("nav-icon-menu_open", "nav-icon-menu_close");
//     menu_open = false;
//   }
// });
// ul_nav.addEventListener("click", (e) => {
//   if (e.target.nodeName === "A") {
//     button_nav.classList.replace("nav-icon-menu_open", "nav-icon-menu_close");
//     ul_nav.style.display = "none";
//     menu_open = false;
//   }
// });
