const Button = ({ name, svg, active, buttonClickHandler, id }) => {
  return (
    <button
      id={id}
      className={active ? "services-button active" : "services-button"}
      onClick={(e) => buttonClickHandler(e, id)}
    >
      {svg}
      <p>{name}</p>
    </button>
  );
};

export default Button;
