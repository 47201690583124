import Button from "./Button";
const ButtonWrapper = ({ buttons, buttonClickHandler }) => (
  <div className="services-wrap-button">
    {buttons.map((button) => (
      <Button
        name={button.name}
        svg={button.svg}
        active={button.isActive}
        key={button.id}
        buttonClickHandler={buttonClickHandler}
        id={button.id}
      />
    ))}
  </div>
);

export default ButtonWrapper;
