const ServicesTable = ({ row, caption, isSearch }) => {
  const row_render = row.map((r, i) => (
    <tr key={i}>
      <td>{r.name}</td>
      <td>{r.price}</td>
    </tr>
  ));
  return (
    <table className="services-table">
      <caption
        style={{
          backgroundColor: isSearch ? "#6b8a92" : "#f8e8d8",
          color: isSearch ? "white" : "black",
        }}
      >
        <p>{caption}</p>
      </caption>
      <tbody>
        <tr>
          <th>Порода/Услуга</th>
          <th>Стоимость</th>
        </tr>
        {row_render}
      </tbody>
    </table>
  );
};
export default ServicesTable;
